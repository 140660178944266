//
import React from 'react'
import useTooltip from 'hooks/useTooltip'
import { useSelector } from 'react-redux'
import UpgradeButton from 'components/ui/UpgradeButton'

// tooltip texts
export const TP_FEDEX =
    'These are the base rates published by FedEx and are not the same as using your own FedEx account rates.'
export const TP_USPS =
    'These are the base rates published by USPS and are not the same as using your own USPS account rates.'
export const TP_UPS =
    'These are the base rates published by UPS and are not the same as using your own UPS account rates.'

export default function ListRatesBanner({ onPrimaryActionClick, carrierType }) {
    return (
        <div className="ent-cont-section list-rates mb20">
            {carrierType === 'fedEx' && (
                <FedexBannerDetails onClick={onPrimaryActionClick} />
            )}
            {carrierType === 'usps' && (
                <USPSBannerDetails onClick={onPrimaryActionClick} />
            )}
            {carrierType === 'ups' && (
                <UPSBannerDetails onClick={onPrimaryActionClick} />
            )}
        </div>
    )
}

const ListRatesTooltip = React.memo(({ text }) => {
    const tooltip = useTooltip()
    return (
        <span
            ref={tooltip.ref}
            className="tool-tip"
            data-place="top"
            data-tip={text}
        >
            <i className="far fa-info-circle" {...tooltip.props} />
        </span>
    )
})

const FedexBannerDetails = React.memo(({ onClick }) => (
    <>
        <div className="txt">
            <p className="strong">
                You are using FedEx&reg; Standard List Rates
                <ListRatesTooltip text={TP_FEDEX} />
            </p>
            <p>
                To use your own fedex account rates, enter your account
                credentials
            </p>
        </div>
        <div className="cta">
            <button onClick={onClick} className="btn btn-primary" type="button">
                Enter Credentials
            </button>
        </div>
    </>
))

const USPSBannerDetails = React.memo(({ onClick }) => (
    <>
        <div className="txt">
            <p className="strong">
                You are using USPS online rates
                <ListRatesTooltip text={TP_USPS} />
            </p>
        </div>
        <div className="cta">
            <button onClick={onClick} className="btn btn-primary" type="button">
                Enter account information
            </button>
        </div>
    </>
))

const UPSBannerDetails = React.memo(({ onClick }) => {
    const listRatesOnly = useSelector(
        ({
            app: { permissions: { list_rates_only: result = true } = {} } = {},
        }) => result
    )

    if (listRatesOnly) {
        return <UPSUpsell />
    }

    return (
        <>
            <div className="txt">
                <p className="strong">
                    You are displaying UPS published daily rates
                    <ListRatesTooltip text={TP_UPS} />
                </p>
                <p>
                    Enter your account information to display UPS account rates.
                </p>
            </div>
            <div className="cta">
                <button
                    onClick={onClick}
                    className="btn btn-primary"
                    type="button"
                >
                    Enter Credentials
                </button>
            </div>
        </>
    )
})

const UPSUpsell = React.memo(() => (
    <>
        <div className="txt">
            <p className="strong">
                You are displaying UPS published daily rates
                <ListRatesTooltip text={TP_UPS} />
            </p>
            <p>
                If you&apos;d like to display your UPS account rates, upgrade
                your account today!
            </p>
        </div>
        <div className="cta">
            <UpgradeButton />
        </div>
    </>
))
