import React from 'react'
import useUpgradePlanUrl from 'hooks/useUpgradePlanUrl'

const UpgradeButton = ({ text }) => {
    const upgradeLink = useUpgradePlanUrl()

    return (
        <a href={upgradeLink} className="btn btn-primary">
            {text}
        </a>
    )
}

UpgradeButton.defaultProps = {
    text: 'Upgrade Now',
}

export default React.memo(UpgradeButton)
